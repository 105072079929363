'use client';

import { useState, useEffect, PropsWithChildren } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography, Stack, CircularProgress } from '@mui/material';
import { formatDate } from '@qb/frontend/utils/dateFormatter';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
const OutsideWrapper = ({
  children
}: PropsWithChildren) => <Stack data-cy="OutsideWrapper" direction="row" sx={{
  gap: 1,
  alignItems: 'center',
  mb: [1, 1, 1.5]
}} data-sentry-element="Stack" data-sentry-component="OutsideWrapper" data-sentry-source-file="PartOnHandQuantityStatus.tsx">
    {children}
  </Stack>;
const TextWrapper = ({
  children,
  bgColor
}: PropsWithChildren<{
  bgColor: string;
}>) => <Stack data-cy="TextWrapper" direction="row" sx={{
  gap: 1,
  alignItems: 'center',
  bgcolor: bgColor,
  px: 1,
  py: 0.5,
  transition: 'all 0.5s ease'
}} data-sentry-element="Stack" data-sentry-component="TextWrapper" data-sentry-source-file="PartOnHandQuantityStatus.tsx">
    {children}
  </Stack>;
export const PartOnHandQuantityStatus = ({
  publicQtyAndPrice
}: Pick<PartDisplayDataResponse, 'publicQtyAndPrice'>) => {
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsFakeLoading(false), 3000);
  }, []);
  if (isFakeLoading) {
    return <OutsideWrapper>
        <TextWrapper bgColor="initial">
          <CircularProgress size={24} sx={{
          color: 'text.primary'
        }} />
          <Typography variant="h6" component="p">
            Checking verified in-stock parts...
          </Typography>
        </TextWrapper>
      </OutsideWrapper>;
  }
  const combinedStock = publicQtyAndPrice.onHandQty + publicQtyAndPrice.manufacturerStock;
  const hasStock = combinedStock > 0;
  const bgColor = hasStock ? 'success.12p' : 'transparent';
  const stockStatusText = hasStock ? `In-stock parts: ${combinedStock.toLocaleString()}` : 'Get a quick Quote!';
  const icon = hasStock ? <CheckCircleIcon color="success" /> : null;
  let updatedAt = '';
  if (publicQtyAndPrice.onHandQtyUpdatedAt) {
    updatedAt = formatDate(publicQtyAndPrice.onHandQtyUpdatedAt);
  } else if (publicQtyAndPrice.manufacturerStockUpdatedAt) {
    updatedAt = formatDate(publicQtyAndPrice.manufacturerStockUpdatedAt);
  }
  return <OutsideWrapper data-sentry-element="OutsideWrapper" data-sentry-component="PartOnHandQuantityStatus" data-sentry-source-file="PartOnHandQuantityStatus.tsx">
      <TextWrapper bgColor={bgColor} data-sentry-element="TextWrapper" data-sentry-source-file="PartOnHandQuantityStatus.tsx">
        {icon}
        <Typography variant="h6" component="p" sx={{
        lineHeight: '24px'
      }} data-sentry-element="Typography" data-sentry-source-file="PartOnHandQuantityStatus.tsx">
          {stockStatusText}
        </Typography>
      </TextWrapper>
      {hasStock && updatedAt && <Typography variant="bodySmall">{updatedAt}</Typography>}
    </OutsideWrapper>;
};