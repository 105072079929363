'use client';

import { Fragment, ElementType } from 'react';
import dynamic from 'next/dynamic';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { List, Divider, ListItemText, ListItemAvatar, Avatar, ListItemButton, SxProps } from '@mui/material';
import { useRightDrawerContext } from '@/global/stores/RightDrawerContext/RightDrawerContext';
import { PartDisplayParams, PartDisplayParamsDrawerType } from '@/shared/classes/SPSPageComponentParams/PartDisplayParams';
const TrustBoxCustomerServiceDrawer = dynamic(() => import('../TrustBoxDrawers/TrustBoxCustomerServiceDrawer').then(mod => mod.TrustBoxCustomerServiceDrawer), {
  ssr: false
});
const TrustBoxPaymentDrawer = dynamic(() => import('../TrustBoxDrawers/TrustBoxPaymentDrawer').then(mod => mod.TrustBoxPaymentDrawer), {
  ssr: false
});
const TrustBoxWarrantyDrawer = dynamic(() => import('../TrustBoxDrawers/TrustBoxWarrantyDrawer').then(mod => mod.TrustBoxWarrantyDrawer), {
  ssr: false
});
const TrustBoxEmptyDrawer = dynamic(() => import('../TrustBoxDrawers/TrustBoxEmptyDrawer').then(mod => mod.TrustBoxEmptyDrawer), {
  ssr: false
});
type ItemProps = {
  primary: string;
  secondary: string;
  icon: string | ElementType;
  onClick?: () => void;
};
const Item = ({
  primary,
  secondary,
  icon,
  onClick
}: ItemProps) => {
  const Icon = icon;
  return <ListItemButton disabled={!onClick} onClick={onClick} alignItems="center" sx={{
    pr: [0, 0, 1],
    pl: [0, 0, 2],
    '&.Mui-disabled': {
      opacity: 1
    }
  }} data-sentry-element="ListItemButton" data-sentry-component="Item" data-sentry-source-file="TrustBoxTopLinks.tsx">
      <ListItemAvatar data-sentry-element="ListItemAvatar" data-sentry-source-file="TrustBoxTopLinks.tsx">
        <Avatar sx={{
        bgcolor: 'primary.main'
      }} src={typeof icon === 'string' ? icon : undefined} data-sentry-element="Avatar" data-sentry-source-file="TrustBoxTopLinks.tsx">
          {typeof icon === 'string' ? undefined : <Icon />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} primaryTypographyProps={{
      variant: 'h6',
      component: 'p',
      mb: 1
    }} secondary={secondary} secondaryTypographyProps={{
      variant: 'bodySmall'
    }} sx={{
      my: 0
    }} data-sentry-element="ListItemText" data-sentry-source-file="TrustBoxTopLinks.tsx" />
      {onClick && <ChevronRightIcon color="primary" fontSize="large" />}
    </ListItemButton>;
};
type SingleDrawerProps = PartDisplayParamsDrawerType & {
  isLast: boolean;
};
export const SingleDrawer = (props: SingleDrawerProps) => {
  const {
    setActiveDrawer
  } = useRightDrawerContext();
  const drawerPaperSx: SxProps = {
    width: ['100%', 512]
  };
  const gridContainerSx: SxProps = {
    px: 0,
    py: 0
  };
  const getFallbackIcon = () => {
    if (props.type === PartDisplayParams.drawerTypes.emptyDrawer) {
      return Fragment;
    }
    if (props.type === PartDisplayParams.drawerTypes.payment) {
      return CreditScoreIcon;
    }
    if (props.type === PartDisplayParams.drawerTypes.warranty) {
      return GppGoodOutlinedIcon;
    }
    if (props.type === PartDisplayParams.drawerTypes.customerService) {
      return SupportAgentIcon;
    }
    return Fragment;
  };
  const getDrawer = () => {
    if (props.type === PartDisplayParams.drawerTypes.payment) {
      return <TrustBoxPaymentDrawer {...props} icon={props.icon || getFallbackIcon()} />;
    }
    if (props.type === PartDisplayParams.drawerTypes.customerService) {
      return <TrustBoxCustomerServiceDrawer {...props} icon={props.icon || getFallbackIcon()} />;
    }
    if (props.type === PartDisplayParams.drawerTypes.warranty) {
      return <TrustBoxWarrantyDrawer {...props} icon={props.icon || getFallbackIcon()} />;
    }
    if (props.type === PartDisplayParams.drawerTypes.emptyDrawer) {
      return <TrustBoxEmptyDrawer {...props} icon={props.icon || getFallbackIcon()} />;
    }
    return <Fragment />;
  };
  return <>
      <Item primary={props.title} secondary={props.subtitle} icon={props.icon || getFallbackIcon()} onClick={() => setActiveDrawer(getDrawer(), drawerPaperSx, gridContainerSx)} data-sentry-element="Item" data-sentry-source-file="TrustBoxTopLinks.tsx" />
      {!props.isLast && <Divider variant="inset" sx={{
      borderColor: 'common.backgroundColor',
      borderBottomWidth: 2,
      mr: 6.5
    }} />}
    </>;
};
type Props = {
  partDisplayParams: PartDisplayParams;
};
export const TrustBoxTopLinks = ({
  partDisplayParams
}: Props) => {
  return <List component="div" sx={{
    borderTopStyle: 'solid',
    borderTopColor: 'common.backgroundColor',
    borderTopWidth: [0, 0, 8],
    py: 1.5,
    px: 0,
    mb: [2, 2, 0]
  }} data-sentry-element="List" data-sentry-component="TrustBoxTopLinks" data-sentry-source-file="TrustBoxTopLinks.tsx">
      {partDisplayParams.drawer1Title && <SingleDrawer title={partDisplayParams.drawer1Title} subtitle={partDisplayParams.drawer1Subtitle} icon={partDisplayParams.drawer1Icon} type={partDisplayParams.drawer1Type} insidePhoto={partDisplayParams.drawer1InsidePhoto} insideText={partDisplayParams.drawer1InsideText} faqTitle1={partDisplayParams.drawer1FaqTitle1} faqSubtitle1={partDisplayParams.drawer1FaqSubtitle1} returnPolicyURL={partDisplayParams.returnPolicyURL} faqTitle2={partDisplayParams.drawer1FaqTitle2} faqSubtitle2={partDisplayParams.drawer1FaqSubtitle2} isLast={false} />}
      {partDisplayParams.drawer2Title && <SingleDrawer title={partDisplayParams.drawer2Title} subtitle={partDisplayParams.drawer2Subtitle} icon={partDisplayParams.drawer2Icon} type={partDisplayParams.drawer2Type} insidePhoto={partDisplayParams.drawer2InsidePhoto} insideText={partDisplayParams.drawer2InsideText} faqTitle1={partDisplayParams.drawer2FaqTitle1} faqSubtitle1={partDisplayParams.drawer2FaqSubtitle1} returnPolicyURL={partDisplayParams.returnPolicyURL} faqTitle2={partDisplayParams.drawer2FaqTitle2} faqSubtitle2={partDisplayParams.drawer2FaqSubtitle2} isLast={false} />}
      {partDisplayParams.drawer3Title && <SingleDrawer title={partDisplayParams.drawer3Title} subtitle={partDisplayParams.drawer3Subtitle} icon={partDisplayParams.drawer3Icon} type={partDisplayParams.drawer3Type} insidePhoto={partDisplayParams.drawer3InsidePhoto} insideText={partDisplayParams.drawer3InsideText} faqTitle1={partDisplayParams.drawer3FaqTitle1} faqSubtitle1={partDisplayParams.drawer3FaqSubtitle1} returnPolicyURL={partDisplayParams.returnPolicyURL} faqTitle2={partDisplayParams.drawer3FaqTitle2} faqSubtitle2={partDisplayParams.drawer3FaqSubtitle2} isLast={false} />}
      {partDisplayParams.drawer4Title && <SingleDrawer title={partDisplayParams.drawer4Title} subtitle={partDisplayParams.drawer4Subtitle} icon={partDisplayParams.drawer4Icon} type={partDisplayParams.drawer4Type} insidePhoto={partDisplayParams.drawer4InsidePhoto} insideText={partDisplayParams.drawer4InsideText} faqTitle1={partDisplayParams.drawer4FaqTitle1} faqSubtitle1={partDisplayParams.drawer4FaqSubtitle1} returnPolicyURL={partDisplayParams.returnPolicyURL} faqTitle2={partDisplayParams.drawer4FaqTitle2} faqSubtitle2={partDisplayParams.drawer4FaqSubtitle2} isLast={false} />}
      {partDisplayParams.drawer5Title && <SingleDrawer title={partDisplayParams.drawer5Title} subtitle={partDisplayParams.drawer5Subtitle} icon={partDisplayParams.drawer5Icon} type={partDisplayParams.drawer5Type} insidePhoto={partDisplayParams.drawer5InsidePhoto} insideText={partDisplayParams.drawer5InsideText} faqTitle1={partDisplayParams.drawer5FaqTitle1} faqSubtitle1={partDisplayParams.drawer5FaqSubtitle1} returnPolicyURL={partDisplayParams.returnPolicyURL} faqTitle2={partDisplayParams.drawer5FaqTitle2} faqSubtitle2={partDisplayParams.drawer5FaqSubtitle2} isLast={true} />}
    </List>;
};