'use client';

import { useEffect, useRef, useState } from 'react';
import { Collapse, Box, Button, Typography } from '@mui/material';
const COLLAPSE_MIN_HEIGHT = 88;
type PartDescriptionCollapseProps = {
  longDescription: string;
  partNumber: string;
};
export const PartLongDescription = ({
  longDescription,
  partNumber
}: PartDescriptionCollapseProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEnoughTextToExpand, setIsEnoughTextToExpand] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const paragraphs = longDescription.split('\n').filter(Boolean);
  useEffect(() => {
    if (contentRef.current) {
      setIsEnoughTextToExpand(contentRef.current.scrollHeight > COLLAPSE_MIN_HEIGHT);
    }
  }, []);
  return <Box sx={{
    minHeight: COLLAPSE_MIN_HEIGHT + 16,
    mt: 4
  }} data-sentry-element="Box" data-sentry-component="PartLongDescription" data-sentry-source-file="PartLongDescription.tsx">
      <Typography variant="h5" component="h3" sx={{
      mb: 2
    }} data-sentry-element="Typography" data-sentry-source-file="PartLongDescription.tsx">
        {`${partNumber} Description`}
      </Typography>
      <Collapse in={isExpanded} collapsedSize={COLLAPSE_MIN_HEIGHT} sx={theme => ({
      my: 2,
      position: 'relative',
      '&::after': {
        display: isExpanded || !isEnoughTextToExpand ? 'none' : 'block',
        content: '"..."',
        typography: 'paragraph',
        background: `linear-gradient(to right, transparent 10%, ${theme.palette.common.white} 60%)`,
        position: 'absolute',
        bottom: 0,
        right: 0,
        pl: 3,
        pb: 0.5
      }
    })} data-sentry-element="Collapse" data-sentry-source-file="PartLongDescription.tsx">
        <Box ref={contentRef} data-sentry-element="Box" data-sentry-source-file="PartLongDescription.tsx">
          {paragraphs.map((paragraph, index) => <Typography key={index} variant="paragraph" align="justify">
              {paragraph}
            </Typography>)}
        </Box>
      </Collapse>
      {isEnoughTextToExpand && <Button onClick={() => setIsExpanded(prev => !prev)} variant="text" color="primary" sx={{
      display: 'block',
      mx: 'auto'
    }}>
          {`See ${isExpanded ? 'less' : 'more'}`}
        </Button>}
    </Box>;
};