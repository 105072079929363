'use client';

import { Box, Typography, Link } from '@mui/material';
type Props = {
  title?: string;
  paragraphStart?: string;
};
export const TrustBoxBottomLinks = ({
  title = 'Need more details?',
  paragraphStart = 'Feel free to '
}: Props) => <Box data-cy="TrustBoxBottomLinks" sx={{
  borderTopStyle: 'solid',
  borderTopColor: 'common.backgroundColor',
  borderTopWidth: [0, 0, 8],
  py: [2, 2, 3],
  px: [3, 4]
}} data-sentry-element="Box" data-sentry-component="TrustBoxBottomLinks" data-sentry-source-file="TrustBoxBottomLinks.tsx">
    <Typography variant="h6" component="p" sx={{
    mb: 2,
    display: {
      xs: 'none',
      md: 'inherit'
    }
  }} data-sentry-element="Typography" data-sentry-source-file="TrustBoxBottomLinks.tsx">
      {title}
    </Typography>
    <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="TrustBoxBottomLinks.tsx">
      {paragraphStart}
      <Link href="#" underline="always" onClick={e => {
      e.preventDefault();
      window?.salesPilotPostMessages?.openChatPage();
    }} title="chat with us" data-sentry-element="Link" data-sentry-source-file="TrustBoxBottomLinks.tsx">
        chat with us
      </Link>
    </Typography>
  </Box>;