'use client';

import { useState, PropsWithChildren, memo } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
const NUM_ABOVE_COLLAPSE_MOBILE = 12;
const NUM_ABOVE_COLLAPSE_DESKTOP = 20;
export const List = ({
  children
}: PropsWithChildren) => <Box component="ul" sx={theme => ({
  listStyle: 'none',
  display: 'grid',
  p: 0,
  m: 0,
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& li:nth-of-type(4n+1), & li:nth-of-type(4n+2)': {
      bgcolor: 'common.backgroundColor'
    },
    '& li:nth-of-type(2n-1)': {
      pr: 2.5
    },
    '& li:nth-of-type(2n)': {
      pl: 2.5
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& li:nth-of-type(2n-1)': {
      bgcolor: 'common.backgroundColor'
    }
  }
})} data-sentry-element="Box" data-sentry-component="List" data-sentry-source-file="PartFeatures.tsx">
    {children}
  </Box>;
export const ListItem = ({
  children
}: PropsWithChildren) => <Typography variant="bodyMedium" component="li" align="right" sx={{
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  p: 0.5
}} data-sentry-element="Typography" data-sentry-component="ListItem" data-sentry-source-file="PartFeatures.tsx">
    {children}
  </Typography>;
export const Name = ({
  children
}: PropsWithChildren) => <Typography variant="bodyMediumPrimary" component="em" align="left" sx={{
  color: 'text.primary',
  width: '50%',
  flexShrink: 0
}} data-sentry-element="Typography" data-sentry-component="Name" data-sentry-source-file="PartFeatures.tsx">
    {children}
  </Typography>;
export const getHasProductDetails = (featureInstances: PartDisplayDataResponse['featureInstances'], basicParametricDatas: PartDisplayDataResponse['basicParametricDatas']) => !!featureInstances?.length || !!basicParametricDatas?.length;
const renderBasicParametricData = (list: PartDisplayDataResponse['basicParametricDatas']) => {
  return list.map(data => {
    return <ListItem key={'basicParametricData_' + data.id}>
        <Name>{data.key} </Name>
        <div dangerouslySetInnerHTML={{
        __html: data.value
      }} />
      </ListItem>;
  });
};
const PartFeatures = ({
  basicParametricDatas = [],
  featureInstances = []
}: Pick<PartDisplayDataResponse, 'basicParametricDatas' | 'featureInstances'> & {
  partAbsoluteUrl: string;
}) => {
  const isMobile = useIsMobile();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const NUM_ABOVE_COLLAPSE = isMobile ? NUM_ABOVE_COLLAPSE_MOBILE : NUM_ABOVE_COLLAPSE_DESKTOP;
  const formattedFeatureInstances = featureInstances.map(({
    id,
    feature,
    value
  }) => ({
    id,
    key: feature.category.name,
    value: `${value} ${feature.unit ?? ''}`
  }));
  const allRows = [...renderBasicParametricData(formattedFeatureInstances), ...renderBasicParametricData(basicParametricDatas)];
  const aboveTheFoldRows = allRows.slice(0, NUM_ABOVE_COLLAPSE);
  const belowTheFoldRows = allRows.slice(NUM_ABOVE_COLLAPSE).map(element => {
    if (isCollapsed) {
      return (
        // Keep the hidden value in the source code for SEO.
        <Box key={`hidden_${element.key}`} sx={{
          display: 'none'
        }}>
          {element}
        </Box>
      );
    }
    return element;
  });
  if (allRows.length === 0) {
    return null;
  }
  return <Box sx={{
    px: [0, 0, 2],
    py: 2
  }} data-sentry-element="Box" data-sentry-component="PartFeatures" data-sentry-source-file="PartFeatures.tsx">
      <Typography variant="h5" component="h2" title="Product Details" sx={{
      mb: 2
    }} data-sentry-element="Typography" data-sentry-source-file="PartFeatures.tsx">
        Product Details
      </Typography>
      <List data-sentry-element="List" data-sentry-source-file="PartFeatures.tsx">
        {aboveTheFoldRows}
        {belowTheFoldRows}
      </List>
      {allRows.length > NUM_ABOVE_COLLAPSE && <Button onClick={() => setIsCollapsed(!isCollapsed)} variant="text" sx={{
      display: 'block',
      mx: 'auto',
      mt: 2
    }}>
          See {isCollapsed ? 'more' : 'less'}
        </Button>}
    </Box>;
};
export const MemoizedPartFeatures = memo(PartFeatures);