import DescriptionIcon from '@mui/icons-material/Description';
import { Box, Typography, Stack, Grid, CardActionArea } from '@mui/material';
import { ReactComponent as IconPdf } from '@/components/Common/Icons/icon-pdf.svg';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { PartDivider } from './PartDivider';
const SingleDocument = ({
  attachment,
  part
}: {
  attachment: PartDisplayDataResponse['documents'][0];
  part: PartDisplayDataResponse['part'];
}) => {
  const nameExtension = attachment.name.toLowerCase().split('.').pop();
  const targetExtension = attachment.target.toLowerCase().split('.').pop();
  const isPdf = nameExtension === 'pdf' || targetExtension === 'pdf';
  return <Grid item xs={12} sm={6} lg={4} key={attachment.id} sx={{
    mb: 3
  }} data-sentry-element="Grid" data-sentry-component="SingleDocument" data-sentry-source-file="PartRelatedDocuments.tsx">
      <CardActionArea target="_blank" rel="noreferrer" title={`${part.partNumber} ${attachment.name}`} href={attachment.target} data-sentry-element="CardActionArea" data-sentry-source-file="PartRelatedDocuments.tsx">
        <Box data-cy="Container" sx={{
        p: 1,
        pt: 5.5,
        bgcolor: 'common.backgroundColor',
        position: 'relative'
      }} data-sentry-element="Box" data-sentry-source-file="PartRelatedDocuments.tsx">
          <Typography data-cy="Name" variant="h5" component="span" sx={{
          p: 2,
          pb: 1,
          display: 'block',
          bgcolor: 'common.backgroundColor',
          wordBreak: 'break-word',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0
        }} data-sentry-element="Typography" data-sentry-source-file="PartRelatedDocuments.tsx">
            {`${part.partNumber} ${attachment.name}`}
          </Typography>
          <Stack data-cy="Preview" sx={{
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
          bgcolor: 'common.white',
          height: 280,
          width: [216, 216, '100%']
        }} data-sentry-element="Stack" data-sentry-source-file="PartRelatedDocuments.tsx">
            {isPdf ? <IconPdf width={113} height={113} /> : <DescriptionIcon sx={{
            fontSize: 112,
            color: 'primary.50p'
          }} />}
          </Stack>
        </Box>
      </CardActionArea>
    </Grid>;
};
export const PartRelatedDocuments = ({
  documents,
  part
}: Pick<PartDisplayDataResponse, 'documents' | 'part'>) => {
  if (documents.length === 0) {
    return null;
  }
  return <>
      <PartDivider data-sentry-element="PartDivider" data-sentry-source-file="PartRelatedDocuments.tsx" />
      <Box sx={{
      py: 2,
      px: [0, 0, 2]
    }} data-sentry-element="Box" data-sentry-source-file="PartRelatedDocuments.tsx">
        <Typography variant="h5" component="h3" title={`${part.partNumber} Related documents`} sx={{
        mb: [3, 3, 2]
      }} data-sentry-element="Typography" data-sentry-source-file="PartRelatedDocuments.tsx">
          Related documents {part.partNumber} Specs
        </Typography>
        <Grid container spacing={3} data-sentry-element="Grid" data-sentry-source-file="PartRelatedDocuments.tsx">
          {documents.map(attachment => <SingleDocument key={attachment.id} part={part} attachment={attachment} />)}
        </Grid>
      </Box>
    </>;
};