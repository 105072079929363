'use client';

import { usePathname } from 'next/navigation';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { BreadcrumbLink } from './BreadcrumbLink';
export const Breadcrumbs = () => {
  const pathname = usePathname() ?? '';
  const pathSegments = pathname.split('/').filter(Boolean);
  const subpagesBreadcrumbs = pathSegments.map((segment, index) => {
    const label = getLabelFromPathSegment(segment);
    const isLastSegment = index === pathSegments.length - 1;
    const href = isLastSegment ? undefined : '/' + pathSegments.slice(0, index + 1).join('/');
    return {
      label,
      href
    };
  });
  const fullBreadcrumbsList = [{
    label: 'Home',
    href: '/'
  }, ...subpagesBreadcrumbs];
  return <MuiBreadcrumbs separator={<Typography sx={{
    color: 'common.white'
  }}>
          /
        </Typography>} data-sentry-element="MuiBreadcrumbs" data-sentry-component="Breadcrumbs" data-sentry-source-file="Breadcrumbs.tsx">
      {fullBreadcrumbsList.map(({
      label,
      href
    }) => <BreadcrumbLink key={label} href={href}>
          {label}
        </BreadcrumbLink>)}
    </MuiBreadcrumbs>;
};
const getLabelFromPathSegment = (segment: string) => {
  const decodedSegment = decodeURIComponent(segment);
  return decodedSegment.charAt(0).toUpperCase() + decodedSegment.slice(1).replace(/-/g, ' ');
};